import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const CreativesPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				antoinette: file(
					relativePath: { eq: "testimonials/antoinette-chappell.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				StuartG: file(
					relativePath: { eq: "testimonials/Stuart Gardiner.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				james: file(relativePath: { eq: "testimonials/james-gasson.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/daniel-brookbank.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;

	const schemaImage = data.heroImg.publicURL;
	const title = "Creatives | Business & Executive Coaching in London";
	const description =
		"A business and executive coach becomes an invaluable asset. By partnering with a coach, creatives can gain the necessary guidance to refine their business models and adopt strategies that not only drive profitability but also foster long-term growth.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const antoinette = data.antoinette.childImageSharp.gatsbyImageData;
	const james = data.james.childImageSharp.gatsbyImageData;
	const StuartG = data.StuartG.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Creatives",
				item: {
					url: `${siteUrl}/industries/creatives`,
					id: `${siteUrl}/industries/creatives`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/creatives`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Business & Executive Coach for Creatives in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Creatives"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<p>
								For creatives, it's not enough to only work 'in' their
								businesses by solely focusing on the creative output; it's
								equally important to work 'on' their businesses. This means
								strategising for growth, optimising operations, and ensuring
								sustainability in a competitive market.
							</p>
							<p>
								A business and executive coach becomes an invaluable asset. By
								partnering with a coach, creatives can gain the necessary
								guidance to refine their business models and adopt strategies
								that not only drive profitability but also foster long-term
								growth. Coaches help direct their efforts toward critical
								business elements that maximise efficiency and profitability,
								ensuring that they can keep up with market demands and stay
								ahead of industry trends, all while continuing to fuel their
								creative passions.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Bespoke and Personalised Coaching for Creatives
							</h3>
							<p>
								Creatives often possess incredible talent and vision but
								struggle with the business aspects of their crafts. Many
								creatives find challenges in areas like pricing, profitability,
								product development, marketing, and establishing efficient
								business processes.
							</p>
							<p>
								Navigating the competitive and rapidly changing landscape of the
								media industry requires more than just artistic skill. It
								demands a strategic approach to business management that
								includes refining your unique style, identifying new market
								segments, and developing effective pricing strategies to enhance
								profitability.
							</p>
							<p>
								With over forty years of business experience and more than seven
								years specialising in coaching, I bring a wealth of knowledge
								tailored specifically to help creatives overcome these
								challenges. In our coaching sessions, we will tackle issues like
								adapting to new technologies, responding to shifting consumer
								preferences, and adjusting to the dynamic demands of the media
								landscape. Together, we’ll focus on strategies that not only
								sustain but grow your creative business in a way that aligns
								with your artistic vision and market opportunities.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								How Business Coaching can bring light to your business
							</h3>

							<p>
								Visual artists and creatives, the journey doesn’t just end at
								creating stunning visuals, it extends into turning these
								creations into a sustainable business. That's where the value of
								a business coach becomes clear, offering guidance tailored to
								the distinct needs of creative professionals.
							</p>
							<p>
								Imagine how you could transform your business with the right
								strategies:
							</p>
							<ul className="pl-4">
								<li>
									<strong>Develop a Targeted Niche Strategy:</strong> Harness
									your unique artistic vision to attract and retain clients that
									not only appreciates but is also willing to invest in
									high-quality visual content. Tailor your marketing efforts to
									resonate deeply with your ideal audience, creating lasting
									connections.
								</li>
								<li>
									<strong>Expand Your Offerings:</strong> Explore adding
									complementary services to broaden your market appeal and
									provide new revenue streams.
								</li>
								<li>
									<strong>Optimise Pricing:</strong> Adjust your pricing
									strategies to reflect the quality and uniqueness of your work.
									A business coach can help you position your offerings in the
									market to ensure you receive fair compensation that reflects
									your creativity and hard work.
								</li>
								<li>
									<strong>Strategically Expand Your Team:</strong> Learn to grow
									your team in a way that supports larger projects and enhances
									your business capacity without diluting the artistic quality
									or personal touch that defines your brand.
								</li>
							</ul>
							<p>
								Partnering with a business coach who is experienced in the
								challenges and opportunities within the visual content industry
								can provide you with the insights and tools needed to navigate
								these complex issues. Together, you can chart a strategic path
								forward, turning your creative passion into a thriving business
								that not only meets but exceeds your aspirations.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">So, what’s been stopping you?</h2>
							<p>
								Do you feel bogged down by the relentless pressure of deadlines
								and project demands, leaving you with little time to strategise
								for growth? If expanding your services, exploring new markets,
								or optimising your pricing models for better profitability seem
								daunting, you are not alone.
							</p>
							<p>
								Here's how a specialised Business & Executive Coach can guide
								you through these complexities:
							</p>
							<ul className="pl-4">
								<li>
									<strong>Strategic Expansion:</strong> Receive tailored advice
									on broadening your service range and identifying new market
									opportunities that align with your unique creative signature.
								</li>
								<li>
									<strong>Pricing Mastery:</strong> Obtain expert assistance in
									evaluating and refining your pricing strategies to ensure they
									reflect the true value of your work and enhance profitability.
								</li>
								<li>
									<strong>Innovative Product Development:</strong> Get support
									in evolving your existing offerings or crafting new services
									that set you apart from the competition.
								</li>
								<li>
									<strong>Effective Team Dynamics:</strong> Learn effective
									strategies to attract, develop, and retain a team that
									supports both your professional goals and your creative ethos.
								</li>
							</ul>
							<p>
								Now is the perfect time to tackle these challenges with the
								backing of an experienced, unbiased, and confidential business
								coach. Let’s partner to turn your creative passion into a
								thriving, sustainable business.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Empowering your business with coaching
							</h2>
							<p>
								As a seasoned business and executive coach with extensive
								experience supporting professionals in the visual arts, I
								understand the specific hurdles you face in today's competitive
								marketplace. Partnering with me means accessing personalised
								coaching sessions that cater directly to your unique needs.
								We'll concentrate on enhancing both your personal and
								professional development to keep you ahead in industry
								advancements. You'll learn to lead and manage a creative team
								effectively, mirroring the innovation of your projects.
								Together, we'll devise strategic business plans that truly
								reflect your distinctive artistic vision and solidify your
								market position. Additionally, we'll focus on innovating your
								products and services to adapt to evolving audience demands and
								maximise new market opportunities.
							</p>
							<p>
								My coaching approach provides customised support and a
								confidential environment where we can boldly tackle your
								challenges and explore exciting new opportunities. This
								comprehensive support is designed to transform your creative
								passion into a thriving, sustainable business.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">About me as a business coach</h2>
							<p>
								As a business coach for creatives, I focus on empowering
								creative specialists not just to create impactful work but also
								to ensure their operations are profitable and sustainable. Our
								sessions provide a secure, confidential space to explore and
								refine your business approach comprehensively.
							</p>
							<p>
								If you're ready to take your creative career to the next level,
								let's connect and embark on this transformational journey.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Stuart Gardiner. Brass Tacks Design London Ltd"
						review="I approached Jason during a financially challenging period, seeking help to restructure my business. Over the course of six months, his impact has been profound. Thanks to his guidance, I have regained my confidence and ambition, and I've attracted more new clients than I ever imagined possible. Jason doesn't offer an easy ride; he challenges you and asks tough questions, which are crucial for identifying necessary changes in your business. His analytical approach, combined with years of real-world business experience, has provided invaluable insights and strategies. I highly recommend Jason to anyone looking to enhance profitability, sharpen their focus, and transform their mindset for the better!"
						linkedIn="https://www.linkedin.com/in/stuart-gardiner-42187016/"
						profilePic={StuartG}
					/>

					<HorizontalTestimonial
						name="Antoinette Chappell. Managing Director. ARC Writing and Translation Services."
						review="I have been working with Jason for six months and in that time I have learnt so much and developed a lot as a person and business owner. What do I like best about working with Jason? His approach to business mentoring, which is both friendly and professional and full of valuable insights into how to successfully run and grow a business. Jason has finely-tuned listening skills and an ability to input information, process it and then output a suggestion that I would never have considered. This means he can drop a career-changing bombshell that ignites a spark that leads to a revolutionary new idea that will transform your life and business forever. He challenges my perception of what I can and can’t achieve, stretching the boundaries of what I thought was possible to the point that the sky is literally the limit! Every time I meet him I come away with my mind buzzing with new exciting ideas about how to improve my business in ways that seem obvious afterwards, but would never have occurred to me on my own. I value his contribution to my business very highly and would happily recommend his services to other businesses that need a fresh approach and guidance from someone with extensive experience of a wide variety of businesses from different sectors."
						linkedIn="https://www.linkedin.com/in/antoinette-chappell-b0a83812a/"
						profilePic={antoinette}
					/>

					<HorizontalTestimonial
						name="James Gasson. Third Circle Recordings."
						review="It seems to me that, for many people, working for yourself can be lonely, confusing and frustrating. After all, we’re good at doing the thing we’re good at, but not necessarily at knowing how to formulate that into a workable, competitive business. I was stuck in this position for many years, not quite confident enough to put my foot down properly, and never sure of the destination were I to do so. Working with Jason has begun to change all that. He has offered direction, focus, and an endless well of positivity, things that have been sorely lacking during my years of struggle and self doubt. His guidance has given me a level of confidence that helps me to stay motivated, even in the face of challenges under which I would otherwise have surely felt defeated, and for that I am extremely grateful. I highly recommend Jason if, like me, you are feeling determined but lost. I look upon our journey with great excitement and readiness, and can’t wait to see where it leads. Five stars indeed."
						linkedIn="https://www.linkedin.com/in/jamesgasson/"
						profilePic={james}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards that positive future?
							</h2>
							<p>
								Unfortunately, though, none of us have a crystal ball. I can’t
								tell you what life will be like if you do, take that job, wake
								up filled with confidence, or grow your business tenfold - but
								what I can tell you is...
							</p>
							<p>If you do nothing, nothing will change.</p>
							<p>
								On the other hand, it’s amazing what we can achieve together
								when we use coaching to focus on what you really want.
							</p>
							<p>
								So, with nothing to lose - why not book a introductory call with
								me?
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Let's schedule a discovery call together</h3>
							<p>
								Why not consider a discovery call with me? This informal chat
								provides an excellent opportunity for us to connect and explore
								how tailored business and executive coaching can not only meet
								but exceed your professional aspirations.
							</p>
							<p>
								Together, we can focus on enhancing your business abilities
								without compromising your creative spirit, ensuring your
								business thrives both artistically and commercially.{" "}
							</p>
							<p>
								Let's discover how you can transform your creative passion into
								a profitable reality.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default CreativesPage;
